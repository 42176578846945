"use client";
import clsx from "clsx";

import Button from "@/components/ui/Button";

import type { FilterModalProps } from "@/lib/centra/hooks/usePlp";
import styles from "./index.module.css";

import Close from "@/components/icons/Close";
import Modal from "@/components/ui/Modal";
import useModal from "@/hooks/useModal";
import { memo, useMemo } from "react";

const FilterBody = ({ filters, title }: { filters: any; title: string }) => {
  return (
    <div className={(styles.filter__content, styles.sizes__features)}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.filter__buttons}>
        {filters?.filters.map(
          (filter: any) =>
            filter.display && (
              <Button
                mode="unset"
                key={filter.value}
                className={clsx(
                  styles.item,
                  styles.size__item,
                  filter.active && styles.active,
                  !filter?.available && styles.hide
                )}
                disabled={!filter?.available}
                onClick={filter.toggle}
              >
                <span>{filter?.value}</span>
              </Button>
            )
        )}
      </div>
    </div>
  );
};

const FilterModalContent = ({
  closeModal,
  filters,
  productCount
}: {
  closeModal: () => void;
  filters: FilterModalProps;
  productCount?: number;
}) => {
  const {
    filterObjects,
    tags,
    clearFilters
    // categories, params
  } = filters;

  const { colors, sizes, features } = useMemo(
    () => ({
      colors: filterObjects?.find((obj) => obj?.name === "color"),
      sizes: filterObjects?.find((obj) => obj?.name === "size"),
      features: filterObjects?.find((obj) => obj?.name === "features")
    }),
    [filterObjects]
  );

  return (
    <div>
      <div className={styles.module__filters}>
        <div className={(styles.filter__content, styles.filter__colors)}>
          <h2 className={styles.title}>Colour</h2>
          <div className={styles.filter__buttons}>
            {colors &&
              colors.filters.map((color) => (
                <Button
                  mode="unset"
                  key={color.value}
                  className={clsx(
                    styles.item,
                    styles.color__blob,
                    styles.colors,
                    color.active && styles.active,
                    !color?.available && styles.hide
                  )}
                  disabled={!color?.available}
                  onClick={color.toggle}
                >
                  <span>
                    <span
                      className={styles.color__indicator}
                      style={{
                        background: `${color?.colorCode}` ?? "lightgray"
                      }}
                    />
                    <span>{color?.value}</span>
                  </span>
                </Button>
              ))}
          </div>
        </div>
        <FilterBody title="Size" filters={sizes} />
        <FilterBody title="Features" filters={features} />
      </div>
    </div>
  );
};

const FilterToolbar = ({ filters }: { filters: FilterModalProps }) => {
  const { closeModal } = useModal("filter");
  const hasFilters = !!filters.tags.length;

  return (
    <div className={styles.toolbar}>
      {hasFilters && (
        <Button
          className={styles.clearButton}
          onClick={() => {
            filters.clearFilters?.();
          }}
        >
          Clear all
        </Button>
      )}

      <Button onClick={closeModal} mode="icon">
        <Close />
      </Button>
    </div>
  );
};

const FilterFooter = ({ productCount }: { productCount?: number }) => {
  const { closeModal } = useModal("filter");
  return (
    <div className={styles.module__footer}>
      <Button color="black" full onClick={closeModal}>
        See all {productCount} results
      </Button>
    </div>
  );
};

const FilterModal = ({
  filters,
  productCount
}: {
  filters: FilterModalProps;
  productCount?: number;
}) => {
  const { closeModal } = useModal("filter");
  const activeFilterCount = filters.tags?.length ?? 0;

  const title = activeFilterCount
    ? `Filters (${activeFilterCount})`
    : `Filters`;

  return (
    <Modal
      title={title}
      modal={"filter"}
      always
      className={styles.filters}
      filters={filters}
      toolbar={<FilterToolbar filters={filters} />}
      footer={<FilterFooter productCount={productCount} />}
    >
      <FilterModalContent
        closeModal={closeModal}
        filters={filters}
        productCount={productCount}
      />
    </Modal>
  );
};
export default memo(FilterModal);
