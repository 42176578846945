"use client";
import clsx from "clsx";
import {
  useCallback,
  useEffect,
  type Dispatch,
  type SetStateAction,
} from "react";

import Popover from "@/components/ui/Popover";
import type { ISortOption, ISortOrderValue } from "@/lib/types/centra";

import Button from "@/components/ui/Button";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import styles from "./sort-modal.module.css";

const SortModal = ({
  sortOrder,
}: {
  sortOrder: {
    setSortOrder: Dispatch<SetStateAction<ISortOrderValue[]>>;
    options: ISortOption[];
    active: ISortOption | undefined;
  };
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams],
  );

  useEffect(() => {
    const urlSort = searchParams.get("sort");
    if (!urlSort) return;
    sortOrder.options.forEach((option) => {
      if (option.key === urlSort) {
        option.setFunction();
      }
    });
  }, []);

  return (
    <Popover modal={"sort"} className={styles.modal}>
      <div className={styles.modal__content}>
        <div className={styles["sort-options"]}>
          {sortOrder.options.map((option) => {
            return (
              <div
                className={clsx(
                  styles["sort-option"],
                  option.active && styles.active,
                )}
                key={option.key}
              >
                <Button
                  mode="unset"
                  onClick={() => {
                    router.push(
                      pathname + "?" + createQueryString("sort", option.key),
                    );
                    option.setFunction();
                  }}
                >
                  {option.display}
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </Popover>
  );
};

export default SortModal;
