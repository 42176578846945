"use client";

import Button from "@/components/ui/Button";
import { PropsWithChildren } from "react";

const FilterButton = ({ children }: PropsWithChildren) => {
  return (
    <Button mode="unset" modal={"filter"}>
      {children}
    </Button>
  );
};

export default FilterButton;
