import type { InfiniteData } from "@tanstack/react-query";

import type { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import type { FilterResponse } from "./useProductFilter";

const productReducer = <PC extends ProductCard>(
  data: InfiniteData<FilterResponse<PC>>
): PC[] => {
  const products = data.pages.reduce(
    (previousValue: PC[], currentValue: FilterResponse<PC>): PC[] => {
      if (currentValue.products.length > 0) {
        const newValue = previousValue.concat(currentValue.products);
        return newValue;
      }
      return previousValue;
    },
    []
  );

  return products;
};

export type IPLPProduct = ReturnType<typeof productReducer>;

export default productReducer;
