import { PRODUCT_LISTING_COUNT } from "@/consts";
import formatProductCard from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import type { GetProductsParameters } from "@/lib/centra/hooks/useProductFilter/getProducts";
import type { IFilterGroup } from "@/lib/types/centra";

const getFilterParameters = (
  count: number,
  productIds: string[] | undefined = undefined,
  categories: string[] = []
) => {
  const category: string[] = categories.filter(Boolean);
  const filterGroups: IFilterGroup[] = [
    {
      name: "features",
      fieldOrFunction: "pr_features.label"
    },
    {
      name: "size",
      fieldOrFunction: "items.name"
    },
    {
      name: "color",
      fieldOrFunction: "sh_swatch.desc"
    }
  ];
  const cardFormatter = formatProductCard;
  return {
    cardFormatter,
    category,
    filterGroups,
    productIds,
    productsPerPage: count
  };
};

export const getProductsFilterParameters = ({
  categories = [],
  productIds = undefined,
  count
}: {
  categories?: string[];
  productIds?: string[] | undefined;
  count: number;
}): GetProductsParameters => {
  const parameteres: GetProductsParameters = getFilterParameters(
    count,
    productIds,
    categories
  );
  return parameteres;
};

export const getSearchFilterParameters = (
  query: string,
  count: number = PRODUCT_LISTING_COUNT
) => {
  const { cardFormatter, filterGroups, productsPerPage } = getFilterParameters(
    count,
    undefined,
    []
  );

  return {
    search: query,
    category: [],
    cardFormatter,
    filterGroups,
    productsPerPage
  };
};
