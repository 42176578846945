import type { IFilterObject } from "@/lib/centra/hooks/useProductFilter/filterFormatter";

export function getSizePriority(item: IFilterObject) {
  if (/^[a-zA-Z\s]{5,}$/.test(item.value)) {
    return 1; // Letters, more than 4 characters
  } else if (/^[a-zA-Z]+$/.test(item.value)) {
    return 2; // Letters, any size
  } else if (/^\d+$/.test(item.value)) {
    return 3; // Numbers
  } else {
    return 4; // Everything else
  }
}
