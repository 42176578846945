"use client";
import type { FetchNextPageOptions, InfiniteData } from "@tanstack/react-query";

import { getProductsFilterParameters } from "@/lib/centra/hooks/plpUseProductsFilter/getProductFilterParameters";

import type { PlpParams } from "@/features/product-listing/components/ProductListingPage/ProductListingPage";
import formatProductsForPlp from "@/features/product/clientUtils/formatProductsForPlp";
import sortSizesByValue from "@/features/product/clientUtils/sortSizesByValue";
import { type InitialPlpData } from "@/features/product/serverUtils/getInitialPlpData";
import { getProducts } from "@/lib/centra/hooks/useProductFilter/getProducts";
import {
  ClearFilters,
  IFilterData,
  ITags,
  SortOrder,
  useProductFilter
} from "@/lib/centra/hooks/useProductFilter/useProductFilter";
import { ProductListingPageStoryblok } from "@/lib/types/storyblok-blok-types";

export function usePlp({
  initialProductData,
  categories,
  initialFilterData,
  bloks,
  search,
  count: initialCount
}: {
  initialProductData: InitialPlpData["products"];
  categories: string[];
  initialFilterData: InitialPlpData["initialFilterData"];
  bloks?: ProductListingPageStoryblok["listingBloks"];
  search?: string;
  count?: number;
}): UsePLPReturn {
  const count = initialCount ?? 1;

  const initialData: InfiniteData<
    Awaited<ReturnType<typeof getProducts>>,
    number
  > = {
    pages: [initialProductData],
    pageParams: []
  };
  const filterParameters = getProductsFilterParameters({ categories, count });
  const initialState = {
    cardFormatter: filterParameters.cardFormatter,
    category: filterParameters.category as string[],
    initialData,
    productIds: filterParameters.productIds,
    count: filterParameters.productsPerPage ?? count,
    initialFilterData,
    search
  };
  const data = useProductFilter(initialState);
  const availableFilters = data.filterObjects?.map((group) => {
    return {
      ...group,
      availableFilters: group.filters.filter((f) => f.available === true)
    };
  });
  return {
    ModalsProps: {
      filterModalProps: {
        filterObjects: sortSizesByValue(availableFilters),
        tags: data.tags,
        clearFilters: data.clearFilters
      },
      SortOrderProps: data.sortOrder
    },
    ProductsData: {
      plpContent: formatProductsForPlp(data.products, bloks, count),
      fetchNextPage: data.fetchNextPage,
      hasNextPage: data.hasNextPage,
      isFetching: data.isFetching,
      productCount: data.productCount
    }
  };
}

export type UsePLPReturn = {
  ModalsProps: {
    filterModalProps: Omit<FilterModalProps, "categories" | "params">;
  } & { SortOrderProps: SortOrder };
  ProductsData: PlpProductsSectionProps;
};
export type PlpModalProps = {
  filters: FilterModalProps;
  productCount?: number;
};

export type FilterModalProps = {
  filterObjects: IFilterData;
  tags: ITags;
  clearFilters: ClearFilters;
  categories?: InitialPlpData["categories"];
  params?: PlpParams;
};

export type PlpProductsSectionProps = {
  plpContent: PLPContent;
  fetchNextPage: FetchNextPageType;
  hasNextPage: boolean | undefined;
  isFetching: boolean;
  productCount?: number;
};

export type PLPContent = ReturnType<typeof formatProductsForPlp>;
export type FetchNextPageType = (
  options?: FetchNextPageOptions | undefined
) => ReturnType<ReturnType<typeof useProductFilter>["fetchNextPage"]>;
