"use client";

import { Suspense } from "react";

import type { PlpModalProps } from "@/lib/centra/hooks/usePlp";
import FilterModal from "./Filter";

const PlpModals = ({ filters, productCount }: PlpModalProps) => {
  return (
    <>
      <Suspense fallback="...">
        <FilterModal filters={filters} productCount={productCount} />
      </Suspense>
    </>
  );
};

export default PlpModals;
