"use client";
import { useEventListener } from "@/hooks/useEventListener";
import useModal from "@/hooks/useModal";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import clsx from "clsx";
import { useCallback, useRef } from "react";
import styles from "./popover.module.css";

const Popover = ({ children, modal, className }: any) => {
  const { isOpen, closeModal } = useModal(modal, {
    lock: false,
    popover: true
  });

  const modalRef = useRef<HTMLDialogElement>(null!);
  const handleClickOutside = useCallback(
    (e: any) => {
      if (!isOpen || !modalRef.current) return;
      if (modalRef.current.contains(e.target) || e.target === modalRef.current)
        return;
      closeModal();
    },
    [isOpen, closeModal]
  );

  useOnClickOutside([modalRef], handleClickOutside);

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (!isOpen) return;

    if (e.key === "Escape") {
      closeModal();
    }
  });

  // Todo - set as any until react types are updated
  const a11yProps: any = isOpen
    ? { "aria-hidden": "false" }
    : { "aria-hidden": "true", inert: "" };

  return (
    <dialog
      open={isOpen}
      ref={modalRef}
      {...a11yProps}
      className={clsx(styles.popover, isOpen ? styles.open : null, className)}
    >
      {children}
    </dialog>
  );
};
export default Popover;
