import type { ICentraFilterGroup } from "@/lib/types/centra";

import type { IFormattedFilters } from "./types";
import type { IFilterData } from "./useProductFilter";

export interface IFilterObject {
  active: boolean;
  available: boolean;
  value: string;
  filterCount: number;
  display: boolean;
  toggle: () => void;
  colorCode?: string;
  colorImage?: string;
  // new
  isOpen?: () => boolean;
  toggleOpen?: () => void;
  isActive?: boolean;
}

const filterFormatter = (
  centraFilters: ICentraFilterGroup[],
  stateFilters: IFormattedFilters | undefined
) => {
  if (!stateFilters) {
    return [];
  }
  const formattedFilters: IFilterData = stateFilters.map((stateFilter) => {
    const allFilters =
      typeof stateFilter.fieldOrFunction === "string"
        ? centraFilters.find(
            (centraFilter) => centraFilter.field === stateFilter.fieldOrFunction
          )
        : stateFilter.fieldOrFunction
          ? stateFilter.fieldOrFunction(centraFilters)
          : undefined;
    const FinishedFilters = allFilters?.values.map((centraFilter) => {
      const { value, filterCount } = centraFilter;
      const active = !!stateFilter.values?.includes(value);
      const available = filterCount > 0;
      const colorCode: string = centraFilter.data.hex
        ? centraFilter.data.hex
        : null;
      const colorImage: string = centraFilter.data.image?.url
        ? centraFilter.data.image.url
        : null;

      const isActive: boolean = stateFilter.isActive(value);

      const filterObject: IFilterObject = {
        active,
        available,
        value,
        filterCount,
        display: true,
        toggle:
          available || active
            ? () => stateFilter.toggle(centraFilter.value)
            : () => {},

        isActive
      };

      if (colorCode) filterObject.colorCode = colorCode;
      if (colorImage) filterObject.colorImage = colorImage;
      return filterObject;
    });
    const isOpen: () => boolean = stateFilter.isOpen;
    const toggleOpen: () => void = stateFilter.toggleOpen;
    return {
      name: stateFilter.name,
      filters: stateFilter.required || !FinishedFilters ? [] : FinishedFilters,
      isOpen,
      toggleOpen
    };
  });
  return formattedFilters;
};

export default filterFormatter;
