const ChevronDown = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 10.5L16 21.5L5 10.5"
        stroke="#161616"
        style={{ stroke: "var(--svg-color)" }}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default ChevronDown;
