"use client";
import type { PlpParams } from "@/features/product-listing/components/ProductListingPage/ProductListingPage";
import { usePlp } from "@/lib/centra/hooks/usePlp";
import { HeroCardStoryblok } from "@/lib/types/storyblok-blok-types";
import { useState } from "react";
import { type InitialPlpData } from "../../serverUtils/getInitialPlpData";
import PlpModals from "../PlpModals";
import PlpTopBar from "../PlpTopBar";
import ProductsSection from "../ProductsSection/ProductsSection";

type Props = {
  initialProductData: InitialPlpData["products"];
  currentCategory: string[];
  categories: InitialPlpData["categories"];
  params?: PlpParams;
  initialFilterData?: InitialPlpData["initialFilterData"];
  listingBloks?: HeroCardStoryblok[];
  search?: string;
  pageSize?: number;
};

const ClientPlp = ({
  categories,
  params,
  initialProductData,
  currentCategory,
  initialFilterData,
  listingBloks,
  search,
  pageSize,
}: Props) => {
  const { ModalsProps, ProductsData } = usePlp({
    initialProductData,
    categories: currentCategory,
    initialFilterData,
    bloks: listingBloks,
    search,
    count: pageSize,
  });
  const [gridSize, setGridSize] = useState(4);

  return (
    <>
      <PlpTopBar
        productCount={ProductsData?.productCount}
        tags={ModalsProps.filterModalProps.tags ?? []}
        gridSize={gridSize}
        setGridSize={setGridSize}
        sortOrder={ModalsProps.SortOrderProps}
      />
      <div style={{ position: "relative" }}>
        <PlpModals
          filters={{
            ...ModalsProps.filterModalProps,
            categories,
            params,
          }}
          productCount={ProductsData?.productCount}
        />
      </div>

      <ProductsSection data={ProductsData} gridSize={gridSize} />
    </>
  );
};

export default ClientPlp;
