import type { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import { ProductListingPageStoryblok } from "@/lib/types/storyblok-blok-types";

const formatProductsForPlp = (
  products: ProductCard[] | undefined,
  bloks: ProductListingPageStoryblok["listingBloks"],
  count: number
) => {
  // divide into arrays of 8
  const formattedProducts: {
    products: ProductCard[];
    editorial?: Required<ProductListingPageStoryblok>["listingBloks"][number][];
  }[] = [];
  if (!products) return formattedProducts;
  for (let i = 0; i < products.length; i += count) {
    formattedProducts.push({
      products: products.slice(i, i + count),
      editorial: bloks?.slice(i, i + 2)
    });
  }
  return formattedProducts;
};
export default formatProductsForPlp;
