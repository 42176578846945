import clsx from "clsx";
import type { HTMLAttributes } from "react";

import styles from "./index.module.css";

const ProductGrid = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return <div className={clsx(styles.grid, className)} {...props} />;
};

export default ProductGrid;
