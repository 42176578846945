"use client";
import { useEffect, useRef } from "react";

const useIntersection = (
  callbackFunction: (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => void,
  options?: IntersectionObserverInit
) => {
  const Ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initiatedOptions = {
      ...options,
      root: options?.root ?? null,
      rootMargin: options?.rootMargin ?? "300px 0px",
      threshold: options?.threshold ?? 0,
    };
    const ref = Ref.current;
    const observer = new IntersectionObserver(
      callbackFunction,
      initiatedOptions
    );
    if (ref) {
      observer.observe(ref);
    }
    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [Ref, callbackFunction, options]);

  return {
    Ref,
  };
};

export default useIntersection;
