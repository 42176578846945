import { useQueryParams } from "@/hooks/useQueryState";

export const CATEGORY_TAG_KEY = "category";

export const useCategoryParams = () => {
  const searchParams = useQueryParams();

  const has = searchParams.has.bind(searchParams, CATEGORY_TAG_KEY);
  const getAll = searchParams.getAll.bind(searchParams, CATEGORY_TAG_KEY);
  const append = searchParams.append.bind(searchParams, CATEGORY_TAG_KEY);
  const deleteMethod = searchParams.delete.bind(searchParams, CATEGORY_TAG_KEY);
  const clear = () => {
    if (has()) {
      deleteMethod();
    }
  };

  return {
    has,
    getAll,
    append,
    delete: deleteMethod,
    clear,
  };
};
