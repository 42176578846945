import { SVGProps } from "react";
export const FourColumns = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <rect width={4.21053} height={20} fill="var(--svg-color)" />
    <rect x={5.26367} width={4.21053} height={20} fill="var(--svg-color)" />
    <rect x={15.7891} width={4.21053} height={20} fill="var(--svg-color)" />
    <rect x={10.5254} width={4.21053} height={20} fill="var(--svg-color)" />
  </svg>
);
export const ThreeColumns = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <rect width={6} height={20} fill="var(--svg-color)" />
    <rect x={7} width={6} height={20} fill="var(--svg-color)" />
    <rect x={14} width={6} height={20} fill="var(--svg-color)" />
  </svg>
);

export const TwoColumns = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <rect x={10.668} width={9.33333} height={20} fill="var(--svg-color)" />
    <rect width={9.33333} height={20} fill="var(--svg-color)" />
  </svg>
);

export const SingleColumn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <rect width={20} height={20} fill="var(--svg-color)" />
  </svg>
);

export const ColumnsIcon = (
  props: SVGProps<SVGSVGElement> & { columns: 1 | 2 | 3 | 4 }
) => (
  <>
    {props.columns === 1 && <SingleColumn {...props} />}
    {props.columns === 2 && <TwoColumns {...props} />}
    {props.columns === 3 && <ThreeColumns {...props} />}
    {props.columns === 4 && <FourColumns {...props} />}
  </>
);
