const functionMap = () => {
  // create a map that has a string as a key and a function as a value
  const funcMap = new Map<FunctionValues, Function>();
  // add the functions to the map below

  return funcMap;
};

export type FunctionValues = "func__garn-categories";
// | "some-other-function"

export const getFunctionFromString = (key: FunctionValues) => {
  const funcMap = functionMap();
  const value = funcMap.get(key);
  return value;
};

export default functionMap;
