import isEqual from "lodash.isequal";
import { useState } from "react";

import type { ISortOption, ISortOrderValue } from "@/lib/types/centra";

export const useSortOrder = () => {
  const [sortOrder, setSortOrder] = useState<ISortOrderValue[]>([]);
  const modifySortOrder = (value: ISortOrderValue[]) => {
    setSortOrder(value);
  };

  const options: ISortOption[] = [
    {
      key: "default",
      display: "Featured",
      value: [],
      setFunction: () => modifySortOrder([]),
      active: isEqual(sortOrder, []),
    },
    {
      key: "newest",
      display: "New arrivals",
      value: [{ field: "createdAt", order: "desc" }],
      setFunction: () =>
        modifySortOrder([{ field: "createdAt", order: "desc" }]),
      active: isEqual(sortOrder, [{ field: "createdAt", order: "desc" }]),
    },
    {
      key: "lowestPrice",
      display: "Price (Low-High)",
      value: [{ field: "priceAsNumber", order: "asc" }],
      setFunction: () =>
        modifySortOrder([{ field: "priceAsNumber", order: "asc" }]),
      active: isEqual(sortOrder, [{ field: "priceAsNumber", order: "asc" }]),
    },
    {
      key: "highestPrice",
      display: "Price (High-Low)",
      value: [{ field: "priceAsNumber", order: "desc" }],
      setFunction: () =>
        modifySortOrder([{ field: "priceAsNumber", order: "desc" }]),
      active: isEqual(sortOrder, [{ field: "priceAsNumber", order: "desc" }]),
    },
  ];

  const active = options.find((option) => option.active);

  return {
    setSortOrder,
    sortOrder,
    options,
    active,
  };
};

export type SortOrder = ReturnType<typeof useSortOrder>;
