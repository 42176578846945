"use client";

import Button from "@/components/ui/Button";
import { PropsWithChildren } from "react";

const SortButton = ({ children }: PropsWithChildren) => {
  return (
    <Button mode="unset" modal={"sort"} popover block>
      {children}
    </Button>
  );
};

export default SortButton;
