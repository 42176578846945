import { InitialPlpData } from "@/features/product/serverUtils/getInitialPlpData";
import { useBoolean } from "@/hooks/useBoolean";
import { ICentraFilterGroup } from "@/lib/types/centra";
import isEqual from "lodash.isequal";
import { useCallback, useMemo, useState } from "react";
import {
  FunctionValues,
  getFunctionFromString
} from "../../plpUseProductsFilter/functionMap";
import type { StringOrFilterValue } from "../../plpUseProductsFilter/types";
import type { IFilters } from "../types";

const formatInitialFilters: (
  initial?: InitialPlpData["initialFilterData"]
) => IFilters | undefined = (initial) =>
  initial?.map((group) => ({
    name: group.name,
    fieldOrFunction: group.fieldOrFunction as StringOrFilterValue,
    required: group.required ?? false,
    labelFieldOrFunction: group.labelFieldOrFunction,
    values: undefined,
    isOpen: true // all accordions open by default
  }));

const useFilters: (initProps?: InitialPlpData["initialFilterData"]) => any = (
  initProps
) => {
  const { value: isModalOpen, toggle: toggleModal } = useBoolean(true);
  const [filters, setFilters] = useState<IFilters | undefined>(
    formatInitialFilters(initProps)
  );

  const modifyValue = useCallback(
    (group: string, value: string, add: boolean, remove: boolean) => {
      const currentGroup = filters?.filter((f) => f.name === group)?.[0];
      if (!currentGroup) return;
      const vals = currentGroup.values ?? [];
      const filteredValues = vals.filter((v: string) => !isEqual(v, value));
      if (filteredValues.length < vals.length) {
        if (remove) {
          setFilters((state) =>
            state?.map((f) => {
              if (f.name === group) {
                return { ...f, values: filteredValues };
              }
              return f;
            })
          );
        }
        return;
      }
      if (add) {
        setFilters((state) =>
          state?.map((f) => {
            if (f.name === group) {
              return { ...f, values: [...vals, value] };
            }
            return f;
          })
        );
      }
    },
    [filters]
  );

  const toggle = useCallback(
    (group: string, value: string) => modifyValue(group, value, true, true),
    [modifyValue]
  );

  const add = useCallback(
    (group: string, value: string) => modifyValue(group, value, true, false),
    [modifyValue]
  );

  const remove = useCallback(
    (group: string, value: string) => modifyValue(group, value, false, true),
    [modifyValue]
  );

  const clear = useCallback((group: string) => {
    setFilters((state) =>
      state?.map((g) => {
        if (g.name === group) {
          return { ...g, values: [] };
        }
        return g;
      })
    );
  }, []);

  const isActive = useCallback(
    (group: string, value: string) => {
      const currentGroup = filters?.filter((f) => f.name === group)?.[0];
      if (!currentGroup) return false;
      return currentGroup.values?.includes(value);
    },
    [filters]
  );

  // toggles the group of filters open/closed
  const toggleGroupOpen = useCallback((group: string) => {
    setFilters((state) =>
      state?.map((f) => {
        if (f.name === group) {
          return { ...f, isOpen: !f.isOpen };
        }
        return f;
      })
    );
  }, []);

  const formatFiltersForExport: any = useMemo(() => {
    return filters?.map((group) => ({
      name: group.name,
      fieldOrFunction: group.fieldOrFunction.startsWith("func__")
        ? (getFunctionFromString(group.fieldOrFunction as FunctionValues) as (
            values: ICentraFilterGroup[]
          ) => ICentraFilterGroup)
        : group.fieldOrFunction,
      required: group.required,
      labelFieldOrFunction: group.labelFieldOrFunction,
      values: group.values ?? undefined,
      toggle: (value: string) => toggle(group.name, value),
      add: (value: string) => add(group.name, value),
      remove: (value: string) => remove(group.name, value),
      clear: () => clear(group.name),
      isOpen: () => filters.find((g) => g.name === group.name)?.isOpen ?? false,
      toggleOpen: () => toggleGroupOpen(group.name),
      isActive: (value: string) => isActive(group.name, value)
    }));
  }, [filters, toggle, add, remove, clear, toggleGroupOpen, isActive]);

  return {
    filters: formatFiltersForExport,
    isModalOpen,
    toggleModal
  };
};

export default useFilters;
